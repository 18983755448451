@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
@import "../../assets/sass/vars";

.blog-slider {
    width: 95%;
    position: relative !important;
    max-width: 800px;
    margin: auto;
    background: linear-gradient(45deg, #ffffff00, white);
    box-shadow: 230px 14px 80px rgba(34, 35, 58, 0.1);
    padding: 25px !important;
    border-radius: 25px;
    height: 455px;
    transition: all .3s;
    overflow: visible !important;

    .blog-slider__code {
        color: $c1;
    }

    @media screen and (max-width: 992px) {
        max-width: 680px;
        height: 455px;
    }

    @media screen and (max-width: 768px) {
        min-height: 500px;
        height: auto;
        margin: 120px auto;
        margin-bottom: 0;
    }

    @media screen and (max-height: 500px) and (min-width: 992px) {
        height: 350px;
    }

    &__item {
        display: flex !important;
        align-items: center;
        opacity: 0;
        transform: all .3s;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        &.swiper-slide-active {
            opacity: 1;

            .blog-slider__img {
                img {
                    opacity: 1;
                    transition-delay: .1s;
                }
            }

            .blog-slider__content {
                >* {

                    opacity: 1;
                    transform: none;

                    @for $i from 0 to 15 {
                        &:nth-child(#{$i + 1}) {
                            transition-delay: $i * 0.1 + 0.1s;
                        }
                    }

                }
            }
        }

    }

    &__img {
        // width: 40%;
        width: 350px;
        flex-shrink: 0;
        height: 100%;
        // background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        // box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
        border-radius: 20px;
        transform: translateX(-80px);

        overflow: hidden !important;

        &:after {
            // content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
            border-radius: 20px;
            opacity: 0.8;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
            opacity: 0;
            border-radius: 20px;
            transition: all .3s;
        }


        @media screen and (max-width: 992px) {
            // width: 45%;
        }

        @media screen and (max-width: 768px) {
            transform: translateY(-50%);
            width: 90%;
        }

        @media screen and (max-width: 576px) {
            width: 95%;
        }

        @media screen and (max-height: 500px) and (min-width: 992px) {
            height: 270px;
        }
    }

    &__content {
        // width: 60%;
        padding-right: 25px;

        @media screen and (max-width: 992px) {
            // width: 55%;
        }

        @media screen and (max-width: 768px) {
            margin-top: -80px;
            text-align: center;
            padding: 0 30px;
        }

        @media screen and (max-width: 576px) {
            padding: 0
        }

        >* {
            opacity: 0;
            transform: translateY(25px);
            transition: all .4s;


        }

    }

    &__code {
        color: #7b7992;
        margin-bottom: 15px;
        display: block;
        font-weight: 500;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        color: #0d0925;
        margin-bottom: 20px;
    }

    &__text {
        color: #4e4a67;
        margin-bottom: 30px;
        line-height: 1.5em;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 10px;
        left: 0;
        width: 100%;

    }

    &__pagination {
        position: absolute;
        z-index: 21;
        right: 20px;
        width: 11px !important;
        text-align: center;
        left: auto !important;
        top: 50%;
        bottom: auto !important;
        transform: translateY(-50%);

        @media screen and (max-width: 768px) {
            transform: translateX(-50%);
            left: 50% !important;
            top: 205px;
            width: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.swiper-pagination-bullets .swiper-pagination-bullet {
            margin: 8px 0;

            @media screen and (max-width: 768px) {
                margin: 0 5px;
            }
        }

        .swiper-pagination-bullet {
            width: 11px;
            height: 11px;
            display: block;
            border-radius: 10px;
            background: #062744;
            opacity: 0.2;
            transition: all .3s;

            &-active {
                opacity: 1;
                background: #fd3838;
                height: 30px;
                box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);

                @media screen and (max-width: 768px) {
                    height: 11px;
                    width: 30px;
                }
            }
        }

    }

}