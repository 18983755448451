@import "./vars";

.gray-box {
    width: 100%;
    height: auto;
    background-color: #f5f5f5;
}

.category-item {
    width: 19%;
    height: auto;
    background: #ffffff;
    cursor: pointer;
    transition: 0.3s all;
    margin-bottom: 15px;
    margin-right: 1%;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    img {
        display: block;
        height: 200px;
        width: 100%;
        object-fit: contain;
        object-position: center;
        margin-bottom: 10px;
    }

}

.categories-box {
    display: flex;
    flex-wrap: wrap;
    flex-basis: content;
}

.width-dropdown {
    width: 30%;
}

.color-select {
    color: $c1;
}

.form-control {
    border-radius: 0;
}

.sidebar {
    border-right: 1px solid #d6d6d6;
}

.input-group input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8f8f8f;
}

.input-group-text {
    background-color: transparent;
    border-right: 0px;
}

.input-group {
    width: 80%;
}

.select-btn {
    width: 50px;
    height: 45px;
    background: #000000;
    border: 1px solid #000000;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #ffffff;
    border-radius: 2px;
}

#unselect-btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
}

.left-btn {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.right-btn {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.product {
    border-top: 1px solid #d6d6d6;
}

#open-btn {
    display: none;
}

.product .item-product {
    width: 33%;
    height: auto;
    transition: 0.5s ease-out;

    .card:hover {
        transform: scaleY(1.05);

        .search-icon {
            display: block;
        }
    }
}

.search-icon {
    display: none;
    height: 38px;
    width: 38px;
    z-index: 100;
    background-color: $c1;
    border-radius: 50%;
    cursor: pointer;
    border: none;
}

.next,
.previous {
    width: 80px;
    height: 45px;
    border: 1px solid #000000;
    background-color: transparent;
}

.page-active {
    background: #2e2e2e;
    border: 1px solid #000000;
}

.vehicle-info {
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    z-index: 10000;
    display: none;
}

.vehicle-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.vehicle-des {
    font-size: 10px;
    line-height: 15px;
    color: #000000;
}

.ul-style {
    list-style: none;
}

.vehicle-info-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.minus,
.plus {
    width: 24px;
    border-radius: 50%;
    height: 24px;
    background: $c1;
    margin: auto;
    border: none;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.number {
    width: 120px;
    height: 40px;
    border: 1px solid #e6e6e6;

    &>span {
        width: 24px;
        text-align: center;
    }
}

.heart {
    width: 40px;
    height: 40px;
    border: 1px solid #e6e6e6;
    background: #fff;
    border-radius: 50px;

    &.active {
        background: rgba(235, 5, 1, 0.08);
        border: $c1;

        svg {
            stroke: $c1;
        }
    }
}

.width-btn {
    width: 30% !important;
}

.large-num {
    font-weight: 700;
    font-size: 28px;
    line-height: 58px;
    letter-spacing: -0.9px;
    color: #000000;
}

.gray-num {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-decoration-line: line-through;
    color: #9098b1;
}

.blue-num {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #006cff;
}

.des-style {
    width: 40%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
}

.product-info-box {
    border: 1px solid #f0f0f0;
    border-radius: 15px;
}

.black-line {
    height: 4.25px;
    background: $g1;
    top: 0;
    display: block;
    width: 17%;
}

.line-between2 {
    display: block;
    width: 100;
    height: 4.25px;
    background-color: #eeeeee;
}

.table {
    border: 1px solid #d1d1d1;
    line-height: 58px;

    tr {
        height: 100px;
    }
}

#head-table {
    background: #fff1f0;
    font-weight: 600;
    font-size: 16px;
    height: 80px;
    color: #000000;

    td,
    th {
        white-space: nowrap;
    }
}

.disable-click {
    border: 1px solid #8f8f8f;
}

.vehicle-book .modal-dialog {
    max-width: 100%;
}

#product1 {
    width: 540px;
    height: 427px;
    border: 1px solid #e6e6e6;
}

#product2 {
    width: 33.3%;
    height: 123px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #eeeeee;
    position: relative;
    margin: 0px 8px;
}

.slider-nav {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#slick {
    .slider-nav {

        .slick-list .slick-track,
        .slick-slider .slick-list {
            transform: translate(-500px, 0px);
        }
    }

    .arrow-prev {
        position: absolute;
        top: 54%;
        left: 5%;
    }

    .arrow-next {
        position: absolute;
        top: 54%;
        left: 90%;
    }
}

.add-vehicle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #006CFF;
    background: none;
    border: none;
    padding: 0;
}

.next-slider,
.prev-slider {
    width: 53px;
    height: 53px;
    background-color: transparent;
    border: 1px solid #000000;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.cart-summary-table {
    width: 100%;
    max-width: 500px;
}