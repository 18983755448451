@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import './vars';

* {
    outline: none;
}

@mixin btn-loading {
    position: relative;
    // font-size: 0;
    color: transparent !important;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background-image: url('../img/loading1.gif');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

body {
    overflow-y: scroll;
}

.hidden {
    display: none !important;
}

.main-navbar {
    position: relative;
    z-index: 6;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: $c1 #f1f1f1;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
    background-color: $c1;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

* {
    font-family: 'Open Sans', sans-serif;
}

.map {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8f8f8f;

    span {
        color: #2e2e2e;
    }
}

/* Home */

.search-box {
    padding: 16px;
    border-radius: 25px;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0.2));
    box-shadow: $sh3;
    margin-bottom: 25px;

    &>h3 {
        font-size: 16px;
        color: $c1;
        font-weight: 600;
        margin: 0;
        margin-bottom: 7px;
    }
}

.des-icon {
    text-align: left;
    margin-left: 25px;

    p {
        margin: 0;
        padding: 0;

        &:nth-child(1) {
            font-weight: 700;
        }
    }
}

.book {
    background-image: url("../img/Rectangle15.png");
    background-repeat: no-repeat;
    margin-top: 50px;
    color: #fff;
    margin-bottom: 50px;
    background-size: cover;
    min-height: 300px;
    text-align: center;

    .container {
        padding-top: 50px;
        padding-bottom: 30px;
        text-align: center;
    }
}

.book-modal .container {
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
}

.book .container>p {
    margin-bottom: 60px;
}

.form-group {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;

    select {
        height: 60px;
        border-radius: 0;
    }
}

.book button,
.book-modal button {
    height: 60px;
    border-radius: 0;
}

.form-btn {
    padding-left: 1.7rem;
}

.book button,
.book-modal button {
    background-color: $c1;
    color: #fff;
    display: block;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.modal.show {
    display: block !important;
}

/* Slide */

.new-btn {
    font-size: 10px;
    cursor: auto;
}


.product-item {
    display: inline-block;
    max-width: 300px;
    width: 100%;
    border-radius: 15px !important;
    border: 1px solid #ddd;
    overflow: hidden;

    a {
        text-decoration: none;
    }

    .product-img {
        padding: 6px;
        display: block;

        img {
            display: block;
            margin: auto;
            width: 100%;
            aspect-ratio: 3/2.2;
            object-fit: cover;
            object-position: center;
            border-radius: 13px;

        }

    }

    .product-body {
        padding: 10px 15px;
        padding-bottom: 0;
    }

    .product-des {
        font-weight: 500;
        font-size: 15px;
        color: #353535;
        font-weight: 600;
        height: 45px;
        overflow: hidden;
        display: block;
        position: relative;
        z-index: 3;
    }

    .product-brand {
        min-height: 21px;
        display: block;
        color: $c1;
        font-weight: bold;
        font-size: 14px;
    }

    .product-bottom {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        align-items: center;
        min-height: 60px;

        .right {
            display: flex;
            align-items: center;

            .xbtn {
                margin-left: 13px;
            }
        }

        .price {
            font-size: 15px;
            font-weight: 600;
            color: $c1;
        }

        .out-of-stock {
            font-size: 15px;
            font-weight: 600;
            color: $c2;
        }

        .old-price {
            color: #767676;
            font-size: 10px;
            -webkit-text-decoration-line: line-through;

            /* Safari */
            text-decoration-line: line-through;
        }

        .fav-btn {
            border: none;
            background: none;

            &.active {

                svg,
                path {
                    fill: $c1 !important;
                    stroke: $c1 !important;
                }
            }
        }
    }

}

.card-footer {
    height: 30px;

    span svg {
        cursor: pointer;
    }
}

.slide .card {
    margin: 10px auto;
}

.card {
    border-radius: 12px !important;
}

.slider h2 {
    text-align: center;
}

.buttons-prev-next {
    margin-right: 10px;

    .slick-next,
    .slick-prev {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
        transform: translate(0, 0);

        svg {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }

        &::before {
            content: none;
        }
    }

    span:first-child {
        margin-right: 15px;
    }
}

.slider .slick-slider>button {
    display: none !important;
}

/* Counter until footer */

.deal-box-1 {
    background: linear-gradient(0deg, rgba(255, 214, 214, .1), rgba(255, 72, 0, 0.06));
    padding: 45px 0;

    .d-title {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: normal;
        text-align: left;
        line-height: unset;
    }

    .counter-wheel {
        flex: 73%;
        background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0.2));
        box-shadow: 230px 14px 80px rgba(34, 35, 58, 0.1);
        border-radius: 25px;
        padding: 0 25px;

        .w-img {
            width: 100%;
            position: relative;
            padding-top: 90%;
            border-radius: 17px;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }

        .price-now {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.055em;
            text-transform: uppercase;
            text-align: center;
            margin-top: 15px;
            color: $c1;
        }

        .old-price {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.03em;
            text-decoration-line: line-through;
            color: #8f8f8f;
        }

        .d-desc {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #8f8f8f;
        }

        .count-num {
            width: 60px;
            height: 80px;
            background: $g1;
            padding: 2px;
            border-radius: 6px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            box-shadow: 230px 14px 80px rgba(34, 35, 58, 0.1);
            margin-right: 27px;
            text-align: center;
            z-index: 6;
            position: relative;

            .content {
                height: 100%;
                width: 100%;
                background: linear-gradient(24deg, #fff, rgba(255, 255, 255, 0.95));
                ;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            span {
                font-weight: 600;
                font-size: 18px;
                color: #000000;
            }

            h3 {
                margin-top: 6px;
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 14px;
                color: #8f8f8f;
            }
        }

    }
}


.deal-box-2 {
    flex: 25%;
    height: 453px;
    background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0.2));
    box-shadow: 230px 14px 80px rgba(34, 35, 58, 0.1);
    border-radius: 25px;

    h3 {
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: $c1;
        padding-bottom: 7px;
    }

    .d-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #8f8f8f;
    }

    .tool1 {
        img {
            display: block;
            margin: 30px auto;
        }

    }
}


/* /////////////////////////////// */

.newToShope {
    flex: 48%;
    height: 240px;

    &>img {
        object-fit: cover;
        object-position: center;
    }
}

.back {
    background: radial-gradient(56.34% 56.34% at 50% 50%, rgba(255, 255, 255, 0.2) 27.48%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, #171717 0%, #2e2e2e 100%);
}

.titleShop {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.055em;
    color: #ff0000;
}

.nameproduct {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #ffffff;
}

.desshope {
    width: 70%;
}

/* //////////////////////////////////////// */

.freatured-brand,
.brand {
    flex: 49%;
    line-height: 39px;
    letter-spacing: 0.015em;
    color: #000000;
}

.freatured-brand {
    h2 {
        font-weight: 700;
        font-size: 26px;
    }

    h3 {
        font-weight: 500;
        font-size: 24px;
    }

    .line {
        display: block;
        width: 35px;
        height: 3px;
        background-color: $c1;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        color: #2e2e2e;
        line-height: 1.4;
        margin-top: 14px;
    }
}

/* /////////////////////////////////// */

.offers {
    background-color: $c1;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.015em;

    h2 {
        font-size: 28px;
        line-height: 42px;
    }

    h3 {
        font-size: 22px;
        line-height: 33px;
    }

    h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }
}

.gray-btn {
    width: 151px;
    height: 54px;
    background: #4b4b4b;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.075em;
    color: #ffffff;
    border: none;
}

.inputemail {
    width: 70%;

    &::placeholder {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.015em;
        color: #767676;
    }
}

.buildimg {
    top: -50%;
    right: 0;
}

.our-company {
    flex: 40%;
}

.our-survice {
    flex: 60%;
}

.footer-phone {
    flex: 37%;
}

.our-company p {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.015em;
    color: #4b4b4b;
    width: 320px;
}

.our-survice {
    div {
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.015em;
        }

        &:first-child a {
            padding-left: 40px;
            color: #8f8f8f;
        }

        &:nth-child(2) a {
            color: #8f8f8f;
            padding-right: 40px;
        }
    }

    .info div {
        color: #676767;
    }
}

.footer .info {
    font-size: 14px;
    font-weight: 500;
}

.footer-bot {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: #2e2e2e;
}

.fit-contain {
    object-fit: contain;
    object-position: center;
}

.products-box {
    display: flex;
    flex-wrap: wrap;

    .product-item {
        width: 32%;
        margin-left: .5%;
        margin-right: .5%;
        margin-bottom: 15px;
        max-width: 100%;
    }
}

.product-details-box {

    .imgs-slider-parent {
        border-right: 1px solid #f0f0f0;
    }

    .brand {
        font-size: 16px;
        color: $c1;
        font-weight: bold;
        margin: 0;
    }

    .title {
        font-size: 27px;
        font-weight: 600;
        line-height: 1.4;
    }

    .price {
        border-radius: 50px;
        background: rgba($color: $c1, $alpha: .1);
        color: $c1;
        padding: 6px 14px;
        font-size: 22px;
        font-weight: bold;
        margin-top: 25px;
        display: inline-block;
    }
}

.imgs-slider {
    .slider-nav {
        .img {
            width: 100%;
            padding-top: 100%;
            position: relative;
            opacity: 0.8;
            transition: 0.3s all;
            transform: scale(0.9);
            cursor: pointer;

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 80%;
                height: auto;
                margin: auto;
                object-fit: contain;
                transition: .3s all;
                border-radius: 15px !important;
            }
        }

        .slick-current {

            .img {
                opacity: 1;
                transform: scale(0.9);
            }

            img {
                box-shadow: $sh2;
                width: 100%;
                border: 2px solid $c1;
            }
        }
    }

    .slider-single {
        .img {
            width: 100%;
            padding-top: 60%;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                max-width: 98%;
                max-height: 100%;
                margin: auto;
                border-radius: 25px;
                object-fit: contain;
            }
        }
    }

}

form.is-loading {
    position: relative;

    button[type=submit] {
        @include btn-loading()
    }

}

button.is-loading {
    @include btn-loading();

    img,
    svg {
        opacity: 0;
    }

    &::after {
        background-image: url('../img/loading3.gif');
    }
}

.dropdown-menu.is-loading {
    @include btn-loading();
    position: absolute;
    pointer-events: all;

    a {
        color: #fff !important;
    }

    &::after {
        background-image: url('../img/loading3.gif');
        background-size: 43px
    }
}

.number.is-loading {
    @include btn-loading();

    &::after {
        background-image: url('../img/loading3.gif');
    }
}

input:disabled {
    opacity: 0.8;
}

.options-box {
    max-height: 400px;
    overflow-y: auto;

    .option-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin: 6px 0;

        .left {
            width: 100%;
            display: flex;
            align-items: center;

            input {
                flex-shrink: 0;
            }

            span {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

        }
    }

}

.empty-box {
    width: 100%;
    display: block;
    text-align: center;
    color: #8F8F8F;
    margin: 50px 0;

    img,
    svg {
        display: block;
        margin: auto;
        margin-bottom: 15px;
    }
}

.added-modal {

    div.left {
        min-height: 100%;
        display: flex;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 20px;

        .m-product-img {
            flex-shrink: 0;
            width: 120px;
            height: 120px;
            object-fit: cover;
            object-position: center;
            margin-right: 10px;
        }

        .content {
            .name {
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.2;
                margin-bottom: 10px;
            }

            .op1 {
                font-size: 15px;
                display: block;
            }
        }
    }
}

body {

    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 0.25rem;
    }

    .page-item {
        display: inline-block;

        &:first-child {
            .page-link {
                margin-left: 0;
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }
        }

        &:last-child {
            .page-link {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
        }
    }

    .page-link {
        width: 45px;
        height: 45px;
        background: #fff;
        border: 1px solid #ddd;
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.02em;
        color: #000;
        border-radius: 60px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin: 0 5px;

        &:hover {
            color: #000;
            text-decoration: none;
            background-color: #e9ecef;
            border-color: #dee2e6;
        }

        &:focus {
            z-index: 2;
            outline: none !important;
            color: $c1;
        }
    }

    .page-item.active {
        .page-link {
            z-index: 3;
            color: #fff;
            background: $g1;
            border: none;
        }
    }

    .page-item.disabled {
        .page-link {
            color: #6c757d;
            pointer-events: none;
            cursor: not-allowed;
            background-color: #fff;
            border-color: #dee2e6;
        }
    }
}

.is-loading {
    pointer-events: none;

    button[type=submit] {
        @include btn-loading()
    }

    &.form-control {
        color: transparent;
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url('../img/loading4.gif');
    }
}

.product-item-sm {
    display: flex;
    align-items: center;

    img {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-color: #ddd;
        margin-right: 10px;
    }

    .ps-n {
        font-size: 16px;
        width: 100%;
        font-weight: 600;
        display: block;
    }
}

.in-search-top-bar {
    background: linear-gradient(-45deg, #ffffff00, rgb(246, 246, 246));
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    justify-content: space-between;

    .left-box {

        display: flex;
    }
}

.product-table{
    overflow-x: auto;
}

.items-table {
    width: 100%;
    text-align: center;

    tr {
        border-bottom: 1px solid #f0f0f0;

        td {
            padding: 15px 0;
        }

        a {
            color: #171717;
            text-decoration: none;

            b {
                color: $c1;
            }
        }
    }
}