@import './vars';

body {
    .only-mob {
        display: none;
    }

    @media (max-width: 1200px) {
        .top-nav {
            box-shadow: $sh3;

            .mid {
                display: none;
            }

            &>.container {
                justify-content: space-between;
            }

            .bar-btn {
                display: inline-block;
                margin-right: 15px;
            }
        }

        .bot-nav {
            display: none;
        }

        .hero-slider-section-row {
            flex-direction: column;

            .col-lg-4,
            .col-lg-8 {
                max-width: 100%;
                width: 100%;
            }

            .side-find-box {
                margin-top: 25px;
            }
        }

        .search-row {
            flex-direction: column;

            .col-lg-3,
            .col-lg-9 {
                width: 100%;
                max-width: 100%;
            }

            .sidebar {
                border-right: 0;
            }
        }

        .in-search-top-bar {
            display: block;

            input,
            select {
                min-width: 0;
                width: auto !important;
            }

            .left-box {
                margin-bottom: 15px;
                justify-content: space-between;
            }

            .right-box {
                justify-content: space-between;
                display: flex;
                flex-direction: row-reverse;
            }

        }

        .filter-btn {
            display: inline-block;
        }

        .filters-box {
            position: fixed;
            bottom: 0;
            height: 100%;
            left: 0;
            right: 0;
            background: #fff;
            box-shadow: $sh2;
            z-index: 999;
            border-radius: 15px;
            margin: 0 auto;
            max-width: 600px;
            width: 95%;
            padding: 15px;
            overflow-y: auto;
            bottom: -100%;
            transition: .3s;
        }

        .filters-back-filter {
            background: #000;
            position: fixed;
            z-index: 888;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: .3s all;
            transition-delay: .2s;
            display: none;
            opacity: 0;
        }

        .filters-box-container.opened {
            .filters-back-filter {
                display: block;
                opacity: .4;
            }

            .filters-box {
                bottom: 0;
            }
        }
    }

    @media (max-width: 1000px) {
        .offer-box {
            flex-direction: column;

            .counter-wheel {
                margin-bottom: 20px;

                .col-md-7,
                .col-md-5 {
                    padding: 15px 0 !important;
                }

                .col-md-5 {
                    margin-top: 35px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .profile-page {
            flex-direction: column;

            .side {
                width: 100%;
            }

            .profile-banner {
                img.profile-img {
                    display: none !important;
                }
            }

            .logout-a {
                display: none !important;
            }

            .steps-pr {
                flex-direction: row;
                overflow-x: auto;

                a {
                    border-right: 1px solid #ddd;
                    white-space: nowrap;

                    .step-item {
                        padding-right: 15px;
                        height: 40px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .main__footer {
            padding-top: 0;
            padding-left: 35px;

            .footer__widget--title {
                margin-top: 30px;
                margin-bottom: 15px;
            }
        }

        .shipping__items {
            width: 100%;
            justify-content: flex-start;

            .shipping__icon {
                width: 40px;
                text-align: center;
            }
        }

        .items-table {
            margin-top: -40px;

            thead {
                display: none;
            }

            tbody {

                tr {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;

                    td {
                        padding: 0 5px;
                    }

                    td:first-child {
                        width: 100%;
                        padding: 0px;
                    }

                    .qty-td {
                        width: 100%;
                        margin: 5px 0;
                    }

                }
            }

            display: block;
        }

        .order-box {

            .col-md-6,
            .col-md-3 {
                margin-bottom: 15px;
            }
        }

        .order-details {
            .product-row {
                flex-direction: column;

                .col-7 {
                    width: 100%;
                }

                .col {
                    display: none;
                }
            }

            .summ {
                .row {
                    flex-direction: row !important;

                    &>* {
                        width: 50%;
                    }
                }
            }
        }
    }

    @media (max-width: 800px) {
        .n-item {
            display: none;
        }

        .n-item-2.only-mob {
            display: inline-block;
        }

        .top-nav {
            padding-top: 15px;
            padding-bottom: 15px;

            .left img {
                width: 200px;
            }
        }

        .products-box .product-item {
            width: 49%;
        }

        .col-only-mob {
            flex-direction: column;

            .xbtn {
                margin: 25px 0;
            }
        }
    }

    @media (max-width: 767px) {
        .in-search-top-bar {
            display: flex;
            flex-direction: column-reverse;

            .left-box {
                width: 100%;
                flex-direction: column;
                justify-content: center;

                .ms-4 {
                    margin: 0 !important;
                }

                .d-flex {

                    margin-top: 15px;
                }
            }

            .right-box {
                width: 100%;
            }
        }
    }

    @media (max-width: 500px) {
        .xtitle {
            font-size: 24px;
        }

        .n-item-2 {
            margin: 0 5px;

            img {
                width: 25px;
            }
        }

        .top-nav {
            .left {
                display: flex;
                align-items: center;
            }
        }
    }
}