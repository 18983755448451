@import "./vars";

.text__secondary {
    color: $c1;
}

.shipping__inner {
    border: 1px solid #242424;
    border-radius: 5px;
    margin-bottom: 25px;
    justify-content: space-between;
}

@media only screen and (max-width: 991px) {
    .shipping__inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10px 0
    }
}

.shipping__items {
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 15px;
    position: relative;
}

.shipping__items::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 48px;
    background: #242424;
    right: 0;
    top: 50%;
    -webkit-transform: translatey(-50%);
    transform: translatey(-50%)
}

@media only screen and (max-width: 991px) {
    .shipping__items::before {
        display: none
    }
}

.shipping__items:last-child::before {
    display: none
}

.shipping__items:hover {
    background: rgba(255, 255, 255, 0.1)
}

.shipping__content--title {
    margin-bottom: 4px;
    font-size: 16px !important;
}

.shipping__content--desc {
    color: #898686;
    margin: 0;
}

.shipping__icon {
    margin-right: 15px;

    svg {
        fill: #fff !important;
    }
}

.newsletter__area {
    padding-bottom: 20px;
    border-bottom: 1px solid #242424;
}

.newsletter__inner {
    gap: 10px;
}

@media only screen and (max-width: 991px) {
    .newsletter__inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
}

@media only screen and (max-width: 991px) {
    .newsletter__content {
        text-align: center;
    }
}

.newsletter__title {
    color: #fff;
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 600;
}

.newsletter__desc {
    color: #898686;
}

@media only screen and (max-width: 575px) {
    .newsletter__subscribe {
        width: 100%;
    }
}

.newsletter__subscribe--form {
    width: 100%;
    position: relative;

    label {
        width: 100%;
    }

    input {

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
            color: #898686;
        }
    }
}

@media only screen and (max-width: 575px) {
    .newsletter__subscribe--form {
        margin: 0 auto;
    }
}

@media only screen and (min-width: 480px) {
    .newsletter__subscribe--form {
        width: 90%;
    }
}

@media only screen and (min-width: 576px) {
    .newsletter__subscribe--form {
        width: 470px;
    }
}

@media only screen and (min-width: 1200px) {
    .newsletter__subscribe--form {
        width: 560px;
    }
}

.newsletter__subscribe--input {
    width: 100%;
    height: 60px;
    border: 1px solid transparent;
    color: #898686;
    background: #1e1e1e;
    font-weight: 500;
    border-radius: 55px;
    padding: 0 130px 0 15px;

    &:focus {
        border-color: $c1;
        color: #fff;
    }
}

.newsletter__subscribe--button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: 0;
    background: $g1;
    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    border-radius: 0 55px 55px 0;
    transition: .3s all;

    &:hover {
        box-shadow: $sh1;
    }
}

.footer__section {
    padding-top: 40px;
}

.footer__bg {
    background: #000;
}

.main__footer {
    padding: 40px 0;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    a {
        color: #fff;
        opacity: .6;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: $c1;
        }
    }
}

.footer__social {
    margin-top: 15px;
}

.social__share--icon__style2 {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    display: inline-flex;
    margin: 5px;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #000;
    text-align: center;
    border-radius: 50%;

    svg {
        fill: #000 !important;
        height: 30px;
    }

    &:hover {
        background: $c1;
        color: #fff;

        svg {
            fill: #fff !important
        }
    }
}

.footer__widget--desc {

    margin-bottom: 0;
    color: #898686;
}

.footer__widget--title {
    margin-bottom: 30px;
    font-weight: 600;
    position: relative;
    font-size: 19px;
    color: #fff;
    text-transform: uppercase;
}


.footer__widget--title__arrowdown--icon {
    position: absolute;
    right: 0;
    top: 38%;
    -webkit-transition: .3s;
    transition: .3s;
    display: none;
}

@media only screen and (max-width: 767px) {
    .footer__widget--title__arrowdown--icon {
        display: block;
    }
}

.footer__widget.active {
    padding-bottom: 14px;

    .footer__widget--title__arrowdown--icon {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.footer__widget--menu__list {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    position: relative;

    img {
        position: absolute;
        left: -30px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 19px;
    }
}

.footer__widget--menu__text {
    color: #898686;
}

.footer__widget--button {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    content: "";
    color: transparent;
    border: 0;
    background-color: transparent;
}

.footer__bottom {
    border-top: 1px solid #242424;
}

.footer__bottom--inenr {
    padding: 10px 0;
}

@media only screen and (max-width: 991px) {
    .footer__bottom--inenr {
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center !important;
    }
}

.copyright__content {
    margin: 0;
    color: #fff;
}

.copyright__content--link {
    color: #fff;

    &:hover {
        color: $c1;
        text-decoration: underline;
    }
}

@media only screen and (max-width: 767px) {
    .copyright__content {
        text-align: center;
    }
}

.footer__bg5 {
    background: -webkit-gradient(linear, left top, right top, from(#f1f2fa), to(#fcedf1));
    background: linear-gradient(to right, #f1f2fa, #fcedf1);
}

.footer__color--style {

    .newsletter__title {
        color: #121416;
    }

    .newsletter__subscribe--input {
        background: #fff;
    }

    .footer__widget--title,
    .footer__widget--desc {
        color: #121416;
    }

    .footer__widget--menu__text {
        color: #121416;

        &:hover {
            color: $c1;
        }
    }

    .copyright__content {
        color: #121416;
    }

    .copyright__content--link {
        color: #121416;

        &:hover {
            color: $c1;
        }
    }
}