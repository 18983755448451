@import "./vars";

.company-register-page {
    display: flex;
    min-height: calc(100vh - 63px);

    .side {
        width: 23%;
        background: #fff;
        box-shadow: rgba($color: #000000, $alpha: 0.16) 0 0px 6px;
        position: relative;
        flex-shrink: 0;

        .profile-banner {
            background-position: bottom;
            background-size: cover;
            background-repeat: no-repeat;
            margin-bottom: 30px;

            .profile-img {
                height: 110px;
                width: 110px;
                margin: auto;
                position: relative;
                top: 25px;
                border-radius: 100px;
                box-shadow: $sh2;
                display: block;
            }

            h1 {
                text-align: center;
                padding-top: 40px;
                font-size: 22px;
            }
        }
    }

    .right-side {
        padding: 15px;
        flex-grow: 1;
        background: #fcfcfc;

        .comer-img {
            height: 300px;
            display: block;
            margin: 15px auto;
            margin-bottom: 25px;
        }

        .profile-img {
            height: 170px;
            width: 170px;
            display: block;
            margin: 15px auto;
            margin-bottom: 25px;

        }
    }
}

.steps-pr {
    display: flex;
    width: 100%;
    flex-direction: column;

    a {
        text-decoration: none;

        &.active .step-item {
            background: #FFEEEE;
            color: $c1;

            i {
                color: $c1;
            }
        }
    }

    .step-item {
        width: 100%;
        height: 50px;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 53px;
        margin-bottom: 6px;
        color: #222;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 17px;
            margin: auto;
            font-size: 25px;
            height: 25px;
            width: 25px;
            color: #646464;
        }

        &.step-done {
            background: #e6fce8;

            i {
                color: rgb(16, 190, 83);
            }
        }
    }

    .space {
        height: 2px;
        width: 60px;
        margin: 0 15px;
        background: #aaa;
    }
}

.step-btns-pr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.imgs-box {
    display: flex;
    flex-wrap: wrap;

    label {
        width: 150px;
        padding: 20px;
        padding-top: 20px;
        border-radius: 15px;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0 2px 10px;
        background: #fff;
        position: relative;
        margin: 15px 15px;
        overflow: hidden;
        cursor: pointer;
        display: block;

        img {
            height: 50px;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
        }
    }
}

.location-pr {
    width: 100%;
    height: 250px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    .map-img {
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
    }
}

.addresses-item {
    border: 1px solid #f0f0f0;
    border-radius: 25px !important;
}