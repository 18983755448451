$animationSpeed: 40s;

@keyframes scroller {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 7))
    }
}

.brands-slider {
    height: 60px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;
    margin: 14px 0;
    top: -60px;
    z-index: 90;

    .b-slide-track {
        animation: scroller $animationSpeed linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .b-slide {
        height: 100px;
        width: 250px;

        img {
            object-fit: contain;
            height: 50px;
            width: 100px;
            opacity: .15;
        }
    }
}