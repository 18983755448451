@import './vars';

.title {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #2e2e2e;
}

.form-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: #676767;
}

.form-control:focus,
.form-select:focus {
    outline: none;
    border-color: #8f8f8f;
    box-shadow: none;
}

input::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #8f8f8f;
}

.forgot-password {
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: #444;
}

.new-account {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #676767;

    a {
        text-decoration: none;
        color: $c1;
    }
}

.btn {
    border-radius: 0 !important;

    &.btn3 {
        background: $c1;
        border-radius: 2.5px;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        border-radius: 7px !important;
        width: auto;
    }
}

.btn2 {
    border: 2px solid !important;
    display: inline-flex !important;
    align-items: center !important;
    font-weight: 500 !important;
    justify-content: center !important;
}


.btn.btn-submit {
    height: 52px;
    border: none;
    background-color: $c1;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
        color: #fff;
        background-color: #03599b;
    }

    &.red {
        color: #fff;
        background-color: $c1;

        &:hover {
            color: #fff;
            background-color: #c40401;
        }

        &.is-loading::after {
            background-color: #ddd;
        }
    }

    &.md {
        font-size: 16px;
        height: 44px;
    }

    &.sm {
        font-size: 14px;
        height: 35px;
    }
}

.btn-submit-nobg {
    height: 52px;
    background: #ffffff;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: $c1;
    border: 2px solid $c1;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 5px;
    }
}

.black-btn {
    color: #000000;
    border: 2px solid #000000;
    height: 39px;
}

.big-btn {
    width: 60%;
}

.modal-body p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

.title-succsess {
    font-weight: 700;
    font-size: 20px;
    line-height: 58px;
    color: #000000;
}

.des-succsess {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #272727;
}

.agree {
    font-weight: 400;
    font-size: 13.5px;
    text-align: left;
    line-height: 20px;
    color: #000000;
    display: block;
}

.form-label {
    font-weight: 500;
}

.faildpassword {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ff0000;
}

.faildsignin {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $c1;
}

.input-faild {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: $c1;
}

.modal-style {
    width: 20%;
    height: 40px;
    background: #ffe0df;
    border: 1px solid $c1;
    top: 10%;
}

.p-style {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #2e2e2e;
}

.main-border {
    width: 100%;
    height: 157px;
    padding: 20px 30px;
    border: 1px solid #8f8f8f;
}

.icon {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    left: 100%;
    top: 30%;
    color: #ff0000;
}

.second-border {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px dashed #aeaeae;
    flex-direction: column;
}

.form-business-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.11em;
    color: #000000;
}

.accordion-button {

    &:not(.collapsed)::after,
    &::after {
        background-image: none;
    }

    &:focus {
        box-shadow: 0 0 4px 0.2rem $c1;
    }

    &:not(.collapsed) {
        border: 1px solid #000000;
        background-color: transparent;
        box-shadow: none;
    }

    border: 1px solid #000000;
}

.radio {
    position: relative;

    input[type="radio"] {
        display: none;
    }

    span {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        box-shadow: 0 0 4px $c1;
        display: block;
        position: absolute;
        left: 0;
        top: 5%;

        &::after {
            content: "";
            height: 8px;
            width: 8px;
            background-color: $c1;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            border-radius: 50%;
            transition: 300ms ease-in-out 0s;
        }
    }

    input[type="radio"]:checked~span::after {
        transform: translate(-50%, -50%) scale(1);
    }
}

.title-select {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.11em;
    color: #373737;
}

.three-digit {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.11em;
    color: #b2acb6;
}

.order-title {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.order-font {
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    color: #000000;
    white-space: nowrap;
}

.order-body {
    width: 100%;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 25px;
}

.order-num {
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: #3a3a3a;
}

.order-date {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #676767;
}

.width-progress {
    width: 20%;
}

.progress-bar1 {
    width: 40%;
    background: $c1;
}

.progress {
    height: 5px !important;
}

.drop-style {
    width: 15%;
    margin: auto;
}

.order-details,
.order-details2 {
    display: none;
}

.order-details-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #272727;
}

.col-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
    color: #000000;
}

.image-product {
    width: 62px;
    height: 62px;
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
    }
}

.details-product {
    font-weight: 400;
    font-size: 15px;
    color: #000000;
}

.color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000000;
}

.space {
    background-color: #f5f5f5;
}

.total-num {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #000000;
}

.progress-bar2 {
    width: 70%;
    background: #e49e4e;
}

.progress-bar-complete {
    width: 100%;
    background: #00ba13;
}

#complete-delivered {
    color: #00ba13;
}

.title2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.history-details {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    color: #818181;
}

.line-between {
    display: block;
    width: 100%;
    height: 0px;
    border: 1px solid #eeee;
}

.col-between {
    width: 308px;
    height: 2px;
    background: #f3f3f3;
    transform: rotate(90deg);
}

.black {
    color: #3a3a3a;
}

.card-summary {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #22262a;
}

.color-card-title {
    color: #6d6d6d;
}

.color-card-num {
    color: #999999;
}

.color-important {
    color: #ff0000;
}

#gray-bg {
    background-color: #4b4b4b;
    width: 75%;
}

.btn-submit-card {
    width: 100%;
    height: 39px;
    border: none;
    background: $c1;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.forget-password-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    color: #272727;
    text-decoration: none;
}

.red-btn {
    background-color: $c1;
}

.promo-btn {
    background-color: rgb(253 250 251);
    position: relative;
    color: $c1 !important;
    border: 2px solid rgb(236, 172, 193);
    border-radius: 55px;
    overflow: hidden;

    &::after {
        opacity: .4;
        content: '';
        top: 0;
        width: 100%;
        left: 0;
        height: 100%;
        position: absolute;
        background-image: url("../img/sale-bg.gif");
        background-size: 110px 110px;
        background-position: center;
        z-index: 1;
    }
}

.line-col {
    display: block;
    width: 100%;
    height: 2px;
    background: #f3f3f3;
    transform: rotate(90deg);
}

.circle {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    background: #000000;
}

.gray-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #4b4b4b;
}

.gray-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4b4b4b;
}

.red-link:hover {
    color: $c1;
}

.border-right {
    height: auto;
    width: 100%;
    border: 1.06321px solid #000000;
    border-right: 17px solid #000000;
}

.form-check-input {
    &:checked {
        background-color: $c1;
        border-color: #272727;
    }

    &:focus {
        box-shadow: none;
    }
}

.default-value {
    font-weight: 500;
    font-size: 14.8849px;
    line-height: 22px;
    color: #cdcdcd;
}

.form-control,
.form-select {
    border-radius: 0 !important;
    padding: 12px 13px !important;
}

.form-select {
    padding-right: 35px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 8px !important;
}

select.is-loading {
    pointer-events: none;
    color: transparent;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('../img/loading4.gif');
}

.complete .accordion-header {
    border: 1px solid #000;
    padding: 20px;
}

.login-box {
    width: 95%;
    max-width: 400px;
    margin: auto;
}