@import '../../assets/sass/vars';

.n-item-2 {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;

    &>img {
        height: 33px;
        margin-right: 10px;
        transition: .3s all;
    }

    .t {
        position: absolute;
        top: -5px;
        right: -10px;
        min-width: 25px;
        height: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $g1;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.1);
        color: #fff;
        font-weight: bold;
        font-size: 17px;
        border-radius: 40px;
    }
}

.n-item {
    display: flex;
    margin-left: 30px;
    align-items: center;

    &>img {
        height: 37px;
        margin-right: 10px;
        transition: .3s all;
    }

    .content {
        .t {
            color: $c1;
            font-size: 14px;
            display: block;
        }

        .b {
            color: #000;
            font-size: 14px;
            display: block;
            position: relative;

            a {
                color: #000;
            }

            img {
                height: 20px;
            }

            .dropdown-menu {
                right: 0;

                a {
                    color: #000;
                }
            }

            &:hover {
                .dropdown-menu {
                    display: block;
                }
            }
        }
    }
}

.top-nav {
    background: #fff;
    display: flex;
    padding-top: 26px;
    padding-bottom: 26px;

    .left {
        img {
            height: auto;
            width: 270px;
            max-width: 90%;
        }
    }

    .mid {
        flex-grow: 1;
        padding: 0 5%;
        max-width: 700px;
        margin: auto;
    }

    .right {
        display: flex;

        .n-item:nth-child(1) {
            margin-left: 0 !important;
        }


    }

    .container {
        font-size: 12px;
        font-weight: 600;
    }

    a>span {
        margin-right: 5px;
    }

}

.search-form {
    display: flex;
    width: 100%;
    border-radius: 70px;

    input {
        flex-grow: 1;
        padding: 10px 25px;
        border: none;
        height: 46px;
        font-size: 19px;
        border: rgb(237, 237, 237) 1px solid;
        background: rgba($color: #000000, $alpha: .02);
        border-right: none;
        border-radius: 70px 0 0 70px;
        transition: .3s all;

        &::placeholder {
            color: #aaa;
        }
    }

    button {
        flex-shrink: 0;
        border: none;
        background: $g1;
        color: #fff;
        height: 46px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 70px 70px 0;

        img {
            height: 20px;
            filter: invert(1);
        }
    }
}

.bot-nav {
    height: 50px;
    background: #11141b;

    .container {
        display: flex;
    }

    .n-item {
        margin-left: 25px;
        text-decoration: none;

        img {
            height: 20px;
        }

        .b {
            font-size: 17px;
        }
    }

    .links {
        display: inline-flex;
        align-items: center;
        height: 50px;
        margin-left: 15px;

        a {
            margin-right: 10px;
            font-size: 15px;
            color: #fff;
            display: block;
            text-decoration: none;
            padding: 6px 15px;
            border-radius: 50px;
            transition: .3s all;

            &:hover {
                background: rgba($color: #fff, $alpha: .2);
            }
        }
    }

    .cats-drop {
        position: relative;

        .cats-nav-btn {
            height: 50px;
            background: $g1;
            border: none;
            color: #fff;
            font-size: 16px;
            padding: 10px 15px;
            min-width: 160px;
            display: inline-flex;
            align-items: center;

            img {
                filter: invert(1)
            }
        }
    }
}


.cats-drop-box {
    background: $g1;
    min-width: 250px;
    padding: 2px;
    border-radius: 15px;
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 10;
    box-shadow: $sh2;

    h3 {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 15px;
        margin: 0;
    }

    .content {
        background: #fff;
        border-radius: 13px;
        overflow: hidden;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 0 18px;
                transition: .3s all;

                a {
                    padding: 13px 0px;
                    padding-right: 40px;
                    display: block;
                    color: #000;
                    border-bottom: 1px solid rgb(231, 231, 231);
                    cursor: pointer;
                    white-space: nowrap;
                    text-decoration: none;
                    position: relative;

                    .arr {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0px;
                        width: 20px;
                        margin: auto 0;
                        transform: rotateZ(-90deg);
                        opacity: .5;
                    }

                    &.back-link {
                        padding-left: 20px;
                        color: $c1;

                        .arr {
                            right: unset;
                            left: -5px;
                            transform: rotateZ(90deg);
                        }
                    }
                }

                &:hover {
                    background: rgba($color: #000000, $alpha: .08);
                }
            }
        }
    }

}

.bar-btn {
    width: 44px;
    height: 44px;
    border: none;
    background: #f8f8f8;
    display: none;
    border-radius: 50px;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-item {
    cursor: pointer;
}