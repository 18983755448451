@import './vars';

.side-nav {
    position: fixed;
    z-index: 999;
    left: -100%;
    top: 0;
    transition: .3s all;
    height: 100%;
    background: #fff;
    width: 300px;
    box-shadow: $sh2;
    overflow-y: auto;

    .top {
        padding: 10px;
        text-align: center;
        margin: 15px 0;

        .logo {
            width: 80%;
            margin-bottom: 15px;
        }

        .search-form {
            input {
                min-width: 0;
            }

            button {
                width: 53px;
            }
        }
    }

    .cats-drop-box {
        box-shadow: none;
        position: static;
        padding: 0;
        border-radius: 0;

        .content {
            border-radius: 0;

            ul li {
                padding: 0;

                a {
                    white-space: normal;
                    padding: 8px 15px;

                    .arr {
                        right: 7px;
                    }
                }

            }
        }
    }

    .links {
        h3 {
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            padding: 8px 15px;
            margin: 0;
            background: $g1;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 0;
                transition: .3s all;

                a {
                    padding: 8px 15px;
                    padding-right: 40px;
                    display: block;
                    color: #000;
                    border-bottom: 1px solid rgb(231, 231, 231);
                    cursor: pointer;
                    white-space: nowrap;
                    text-decoration: none;
                    position: relative;
                }

                &:hover {
                    background: rgba($color: #000000, $alpha: .08);
                }
            }
        }
    }
}

.side-back-filter {
    background: #000;
    position: fixed;
    z-index: 888;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s all;
    transition-delay: .2s;
    display: none;
    opacity: 0;
}

.side-nav-box.opened {
    .side-back-filter {
        display: block;
        opacity: .4;
    }

    .side-nav {
        left: 0;
    }

}