@import './vars';

.xlabel {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 3px;
    display: inline-block;
    position: relative;
}

.xinput {
    width: 100%;
    padding: 10px 17px;
    border-radius: 50px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    display: block;
    transition: .3s all;
    background: #fff;

    &:focus {
        box-shadow: $sh2;
        transform: scale(1.06);
    }
}

textarea.xinput {
    border-radius: 25px;
}

.xbtn {
    display: inline-flex;
    background-image: linear-gradient(147deg, #fe8a39 0%, $c1 74%);
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: $sh1;
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    min-width: 140px;
    transition: .3s all;
    transition-delay: 0s !important;
    white-space: nowrap;
    border: none;

    &.xbtn-circle {
        width: 40px;
        height: 40px;
        min-width: 0;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-self: center;
    }

    &.sm {
        padding: 7px 13px;
        box-shadow: $sh2;
    }


    &.xsm {
        padding: 7px 15px;
        box-shadow: none;
        min-width: 0;
    }

    img {
        width: 23px;
        filter: invert(1);
    }

    &:hover {
        color: #fff;
        box-shadow: 0px 7px 30px rgba(252, 56, 56, 0.4);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .4;
    }

    &.no-bg {
        background: none;
        box-shadow: none;
        color: #000;
    }
}

.xtitle {
    text-align: center;
    font-size: 29px;
    font-weight: 600;
    color: #000;
    margin-top: 10px;
    margin-bottom: 20px;

    span {
        display: inline-block;
        position: relative;

        b {
            position: relative;
            background: rgba(255, 255, 255, 0.7);
            z-index: 2;
            border-radius: 9px;
            display: inline-block;
            padding: 3px 7px;
            backdrop-filter: blur(5px);
        }

        &::after {
            content: '';
            background: $g1;
            position: absolute;
            width: 30px;
            height: 30px;
            left: -7px;
            bottom: -7px;
            border-radius: 6px 0 6px 15px;
            z-index: 1;
        }
    }


}

.pac-container {
    z-index: 100000 !important;
}