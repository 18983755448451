@import '../../assets/sass/vars';


.hero-slider-section-over {
    position: relative;
    top: -60px;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0.2));
    height: 60px;
}

.hero-slider-section {
    padding: 50px 0;
    background-image: url('../../assets/img/hero.jpg');
    background-size: cover;
    min-height: 500px;
    overflow: hidden !important;
    position: relative;

    .xinput {
        margin-bottom: 15px;
    }

    .side-find-box {
        width: 100%;
        // border: 1px solid $c1;
        max-width: 470px;
        margin: auto;
        border-radius: 25px;
        padding: 25px;
        position: relative;
        z-index: 6;
        background: linear-gradient(-45deg, #ffffff00, white);
        box-shadow: 230px 14px 80px rgba(34, 35, 58, 0.1);
    }

    .s-top {
        margin-bottom: 15px;

        h3 {
            font-size: 22px;
            font-weight: 600;
            margin: 0;
        }

        p {
            font-size: 15px;
            margin: 0;
            color: #777;
            margin-top: 10px;
        }
    }
}