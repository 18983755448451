@import "./vars";

.single-contact-wrap {
  padding: 35px 30px 40px;
  transition: all 0.3s ease 0s;
  @media (max-width: 500px) {
    padding: 35px 20px 40px;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 12px;
  }
  p {
    font-size: 20px;
    color: #444;
    margin: 0;
    @media (max-width: 1000px) {
      font-size: 18px;
    }
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  &:hover {
    background-color: #fff;
  }
}

.contact-map {
  #map {
    height: 500px;
    @media (max-width: 991px) {
      height: 400px;
    }
    @media (max-width: 778px) {
      height: 350px;
    }
    @media (max-width: 481px) {
      height: 300px;
    }
    @media (max-width: 500px) {
      height: 350px;
    }
  }
}

.contact-us-bg {
  background-color: #f7f9fb;
  //   background-image: url(../../assets/images/bg/bg-3.png);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
}

.contact-from-area {
  &.contact-from-area-bg {
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.15);
    padding: 63px 65px 66px;
    border-radius: 5px;
    @media (max-width: 991px) {
      padding: 63px 35px 66px;
    }
    @media (max-width: 481px) {
      padding: 53px 20px 56px;
    }
  }
  h3 {
    font-size: 34px;
    font-weight: 500;
    margin: 0 0 50px;
    text-align: center;
    @media (max-width: 481px) {
      font-size: 25px;
      margin: 0 0 30px;
    }
  }
  .contact-form-style {
    .textarea-style {
      textarea {
        background-color: #f8f8f8;
        border: 1px solid #f8f8f8;
        height: 48px;
        color: #666;
        padding: 12px 20px 3px;
        border-radius: 5px;
        font-size: 15px;
        height: 150px;
        &::-moz-input-placeholder {
          color: #666;
          opacity: 1;
        }
        &::-webkit-input-placeholder {
          color: #666;
          opacity: 1;
        }
        &:focus {
          background-color: #ffffff;
          border: 1px solid #4e97fd;
        }
      }
    }
    button {
      font-size: 15px;
      font-weight: 700;
      padding: 12px 41px;
      color: #ffffff;
      border: none;
      background-color: #4e97fd;
      width: 100%;
      border-radius: 26px;
      &.submit-auto-width {
        width: auto;
        display: inline-block;
      }
      &:hover {
        background-color: $c1;
      }
    }
  }
  p {
    font-size: 15px;
    text-align: center;
    margin: 20px 0 0;
  }
}

.contact-info-wrap {
  margin: 0 0 0 30px;
  @media (max-width: 778px) {
    margin: 50px 0 0 0px;
  }
  @media (max-width: 481px) {
    margin: 50px 0 0 0px;
  }
  .single-contact-info2-wrap {
    display: flex;
    align-items: center;
    margin: 0 0 55px;
    @media (max-width: 481px) {
      margin: 0 0 35px;
    }
    &:last-child {
      margin: 0 0 0px;
    }
    .single-contact-info2-icon {
      margin: 0 25px 0 0;
      i {
        font-size: 40px;
        color: $c1;
        @media (max-width: 481px) {
          font-size: 35px;
        }
      }
    }
    .single-contact-info2-content {
      p {
        font-size: 16px;
        line-height: 1.625em;
        color: #444;
        margin: 0 0 0px;
      }
      h2 {
        font-size: 48px;
        font-weight: 700;
        margin: 10px 0 0px;
        line-height: 40px;
        @media (max-width: 991px) {
          font-size: 40px;
        }
        @media (max-width: 778px) {
          font-size: 40px;
        }
        @media (max-width: 481px) {
          font-size: 25px;
          line-height: 25px;
        }
        a {
          color: #000;
          &:hover {
            color: $c1;
          }
        }
      }
      h3 {
        font-size: 34px;
        margin: 18px 0 0px;
        line-height: 30px;
        @media (max-width: 991px) {
          font-size: 30px;
        }
        @media (max-width: 778px) {
          font-size: 30px;
        }
        @media (max-width: 481px) {
          font-size: 25px;
          margin: 10px 0 0px;
          line-height: 25px;
        }
        a {
          color: #000;
          &:hover {
            color: $c1;
          }
        }
      }
      h4 {
        font-size: 20px;
        margin: 8px 0 0px;
        line-height: 30px;
        @media (max-width: 481px) {
          font-size: 18px;
          line-height: 28px;
        }
        a {
          color: #000;
          &:hover {
            color: $c1;
          }
        }
      }
    }
  }
}

.single-contact-info3-wrap {
  display: flex;
  .single-contact-info3-icon {
    margin-right: 20px;
    @media (max-width: 481px) {
      margin-right: 12px;
    }
    i {
      font-size: 32px;
      color: $c1;
      @media (max-width: 481px) {
        font-size: 28px;
      }
    }
  }
  .single-contact-info3-content {
    h3 {
      font-size: 18px;
      font-weight: 700;
      margin: 0 0 23px;
      @media (max-width: 481px) {
        margin: 0 0 10px;
      }
    }
    p {
      font-size: 15px;
      color: #444;
      margin: 0 0 3px;
      &.width-1 {
        width: 90%;
        @media (max-width: 481px) {
          width: 100%;
        }
      }
      span {
        font-weight: 700;
        color: #000;
      }
    }
  }
}

@media (max-width: 481px) {
  .mb-60 {
    margin-bottom: 30px;
  }
  .contact-us-area {
    &.pt-65 {
      padding-top: 55px;
    }
  }
  .contact-info-wrap-2 {
    &.mb-40 {
      margin-bottom: 25px;
    }
  }
}
